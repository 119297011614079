<template>
  <a-modal
    :title="$t('common.user_defined_box_no')"
    :visible="state.visible"
    :centered="true"
    :mask-closable="false"
    @cancel="close"
    @ok="handleOk"
  >
    <a-textarea ref="boxRef" v-model:value="state.listValue" :auto-size="{ minRows: 5, maxRows: 8 }" @blur="handleBoxesBlur" @change="handleBoxesChange" />
    <a-row type="flex" justify="space-between" :gutter="[32, 32]">
      <a-col flex="1" class="pt-3">
        <small>{{ $t('logistics.user_defined_box_no_maximum_of_p0_characters', [state.boxNoMaxLength]) }}</small>
      </a-col>
      <a-col>
        <small :class="getBoxNoCount > max ? 'text-error' : 'text-grey'">{{ $t('common.total') }}: {{ getBoxNoCount }}/{{ max ?? 1 }}</small>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref, nextTick, watch } from 'vue';
import { Col, Input, Modal, Row } from 'ant-design-vue';
import { refInputElemFocus } from '@/utils/domoperate.js';

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    ATextarea: Input.TextArea,
    ARow: Row,
    ACol: Col,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => ([])
    },
    max: {
      type: Number,
      default: 1
    }
  },
  setup (props, { emit }) {

    const boxRef = ref(null);
    
    const state = reactive({
      visible: false,
      listValue: null,
      boxNoMaxLength: 35,
    })

    const getBoxNoList = (boxes = "") => {
      if (!boxes) {
        return [];
      }
      boxes = boxes.replace(/[\s,;，；]/g, " ");
      let result = boxes.split(" ").filter(Boolean).filter(item => item.length <= state.boxNoMaxLength).map((item) => item.trim());
      return result;
    }

    const handleBoxesBlur = () => {
      let list = getBoxNoList(state.listValue);
      let max = parseInt(props.max);
      list.length = Number.isNaN(max) ? 1 : max;
      state.listValue = list.join('\n');
    }

    const handleBoxesChange = () => {
      let value = state.listValue;
      if (value) {
        value = value.replace(/[^\w\/\-\\\s,;；]/g, "");
        state.listValue = value;
      }
    }

    const handleOk = () => {
      let list = getBoxNoList(state.listValue);
      emit('confirm', list);
      close();
    }

    const close = () => {
      emit('update:visible', false);
    }

    const getBoxNoCount = computed(() => {
      return getBoxNoList(state.listValue).length;
    })

    watch(() => [props.visible, props.list], ([visible, list]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            refInputElemFocus(boxRef.value);
          });
          state.listValue = list.join("\n");
        }
      }
    }, { immediate: true, deep: true })

    return {
      boxRef,
      state,
      getBoxNoCount,
      handleBoxesBlur,
      handleBoxesChange,
      handleOk,
      close,
    }
  }
})
</script>

<style scoped>

</style>