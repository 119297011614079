<!-- 快速导入产品 -->
<template>
  <a-modal
    :width="1200"
    v-model:visible="visible"
    :title="$t('warehouse.quick_import')"
    :mask-closable="false"
    :centered="true"
    :ok-button-props="{ disabled: disabledConfirmBtn }"
    @ok="handleConfirm"
  >
    <a-row :gutter="16">
      <a-col>
        <a-upload
          name="template"
          list-type="text"
          accept=".xlsx"
          :show-upload-list="false"
          :customRequest="handleUpload"
        >
          <a-button>
            <loading-outlined v-if="uploadLoading"></loading-outlined>
            <upload-outlined v-else></upload-outlined>
            {{ $t("warehouse.select_import_file") }}
          </a-button>
        </a-upload>
      </a-col>
      <a-col>
        <a-button type="link" :loading="downloadLoading" @click="handleDownloadExcel">{{ $t('warehouse.click_download_template') }}</a-button>
      </a-col>
    </a-row>
    <a-empty v-if="boxList.length === 0" :image="simpleImage"></a-empty>
    <a-card class="mt-3" size="small" v-for="(card, i) in boxList" :key="i" :body-style="{ paddingLeft: '100px' }" hoverable>
      <template #title>
        <span class="card-title">{{ i + 1 }}. {{ card.totalCount }} {{ $t('warehouse.box') }}</span>
      </template>
      <a-table
        size="small"
        :columns="columns"
        :data-source="[card]"
        :pagination="false"
        :row-key="(record) => record.rowKey"
      >
        <template #serialNumber="{ index }">
          {{ index + 1 }}
        </template>
        <template #selfBoxNo="{ record }">
          <template v-if="record.selfBoxNoList.length > 0">
            <a-tag><span>{{ record.selfBoxNoList[0] }}</span></a-tag>
            <a-popover :title="$t('common.user_defined_box_no')" placement="right" trigger="click" v-if="record.selfBoxNoList.length > 1">
              <template #content>
                <div style="max-width: 560px;">
                  <a-tag class="mb-2" v-for="(tag, ind) in record.selfBoxNoList" :key="ind">
                    <span>{{ tag }}</span>
                  </a-tag>
                </div>
              </template>
              <a-tag color="processing"><span>+{{ record.selfBoxNoList.length - 1 }} ...</span></a-tag>
            </a-popover>
          </template>
          <small v-else class="text-grey">{{ $t('common.nothing') }}</small>
        </template>
        <template #encasementSize="{ record }">
          {{ record.containerLength || 0 }}x{{ record.containerWidth || 0 }}x{{ record.containerHeight || 0 }}cm
        </template>
        <template #encasementWeight="{ record }">
          {{ gToKg(record.containerWeight) }} kg
        </template>
        <template #containerCount="{ record }">
          {{ record.containerCount || 0 }} Unit
        </template>
        <template #error="{ record }">
          <a-popover v-if="!record.isNormal">
            <template #content>
              <div v-for="(item, index) in record.errObjs" :key="index">{{ item.msg }}</div>
            </template>
            <WarningOutlined class="text-error" style="font-size: 20px;" />
          </a-popover>
          <a-popover v-else-if="record?.productIsError">
            <template #content>
              {{$t('warehouse.product_import_error_prompt')}}
            </template>
            <WarningOutlined class="text-error" style="font-size: 20px;" />
          </a-popover>
        </template>
        <template #expandedRowRender="{ record }">
          <a-table
            :columns="innerColumns"
            :data-source="record.items"
            :pagination="false"
            :row-key="(record) => record.rowKey"
            size="small"
          >
            <template #productInfo="{ record }">
              <a-row :gutter="8" type="flex" align="middle">
                <a-col :span="4">
                  <div class="table-list-img-common">
                    <c-image
                      :src="record.imgUrl"
                      :thumbWidth="600"
                      :thumbHeight="600"
                    />
                  </div>
                </a-col>
                <a-col :span="20">
                  <div>{{ record.productName }}</div>
                  <div>{{ record.productNo }}</div>
                </a-col>
              </a-row>
            </template>
            <template #containerCount="{ record }">
              {{ record.containerCount || 0 }} Unit
            </template>
            <template #error="{ record }">
              <a-popover v-if="!record.isNormal">
                <template #content>
                  <div v-for="(item, index) in record.errObjs" :key="index">{{ item.msg }}</div>
                </template>
                <WarningOutlined class="text-error" style="font-size: 20px;" />
              </a-popover>
            </template>
          </a-table>
        </template>
      </a-table>
    </a-card>
    <a-row class="mt-2" type="flex" justify="space-between">
      <a-col>{{ $t('warehouse.when_you_confirm_the_import_only_the_correct_product_data_will_be_imported') }}</a-col>
      <div>{{ $t('common.total') }}: {{ getTotalBoxCount }} {{ $t('warehouse.box') }}</div>
    </a-row>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { Button, Card, Col, Empty, Modal, Popover, Row, Table, Tag, Upload, message } from 'ant-design-vue';
import CImage from '@/views/components/CImage.vue';
import { useI18n } from "vue-i18n/index";
import { downloadFile } from '@/utils/downloader.js';
import { uploadParseExcelData } from '@/api/modules/consignment/inbound.js';
import {getGenerateTemplate } from "@/api/modules/transportation/index";
import { gToKg, kgToG, dateString, randomString } from '@/utils/general.js';
import { maxUploadFileSizeByte } from '../../../../utils/common';

export default defineComponent({
  expose: ['open'],
  emits: ['confirm'],
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AUpload: Upload,
    AButton: Button,
    ACard: Card,
    ATable: Table,
    APopover: Popover,
    AEmpty: Empty,
    ATag: Tag,
    APopover: Popover,
    CImage,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    
    const state = reactive({
      visible: false,
      uploadLoading: false,
      downloadLoading: false,
      disabledConfirmBtn: false,
      boxList: [],
    });

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 250,
        title: () => vueI18n.t("common.user_defined_box_no"),
        slots: {
          customRender: "selfBoxNo"
        }
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 100,
        slots: {
          customRender: "error"
        }
      },
    ];

    const innerColumns = [
      {
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        },
        width: 300,
      },
      {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 100,
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 100,
        slots: {
          customRender: "error"
        }
      },
    ];

    const getCardKey = (item) => {
      let length = item.encasementLength || item.containerLength;
      let width = item.encasementWidth || item.containerWidth;
      let height = item.encasementHeight || item.containerHeight;
      let weight = (item.encasementGrossWeight ? kgToG(item.encasementGrossWeight) : 0) || item.containerWeight;

      let key = length + '_' + width + '_' + height + '_' + weight;
      let list = item?.items ?? item.detailProducts;
      list.forEach(product => {
        key += '_' + product.seSku + '_' + product.containerCount || product.productCount;
      })
      return key;
    };

    const handleUpload = (file) => {
      if (file?.file?.size > maxUploadFileSizeByte() ) {
        message.error(vueI18n.t('common.please_upload_file_with_size_less_than_50MB'));
        return
      }

      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      uploadParseExcelData(parameter).then(({ result }) => {
        if (Array.isArray(result)) {
          let boxList = [];
          let keysList = [];
          result.forEach(item => {
            let obj = Object.assign({}, item, { rowKey: randomString(12) });
            obj.totalCount = 1;
            obj.selfBoxNoList = [];
            obj.items.forEach(ite => {
              ite.rowKey = randomString(6);
              if (!ite.isNormal) {
                item.productIsError = true;
              }
            });

            let key = getCardKey(obj);

            if (keysList.includes(key)) {
              let index = keysList.findIndex(item => item === key);
              boxList[index].totalCount++;
              if (obj.selfBoxNo) {
                boxList[index].selfBoxNoList.push(obj.selfBoxNo);
              }
            } else {
              obj.selfBoxNoList = [obj.selfBoxNo];
              boxList.push(obj);
              keysList.push(key);
            }
          });

          state.boxList = boxList;
        } else {
          state.boxList = []
        }
      }).catch(() => {}).finally(() => {
        state.uploadLoading = false;
      })
    }

    const handleDownloadExcel = () => {
      state.downloadLoading = true
      let url = getGenerateTemplate()
      downloadFile(url, `import_box_${dateString()}.xlsx`, "GET")
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          state.downloadLoading = false
        })
    }

    const handleConfirm = () => {
      let boxList = state.boxList.map(item => {
        item.containerWeight = item.containerWeight ? gToKg(item.containerWeight) : null;
        item.items = item.items.map(ite => {
          ite.id = ite.productId;
          if (!ite.errObjs || !ite.errObjs.length) {
            return ite;
          }
        }).filter(Boolean);
        return item;
      });
      let list = boxList.filter( x => x.isNormal && !x.productIsError);
      if (list.length > 0) {
        state.disabledConfirmBtn = true;
        emit('confirm', list);
        close();
      } else {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.box_list')]));
      }
    }

    const getTotalBoxCount = computed(() => {
      return state.boxList.reduce((total, item) => {
        return total + item.totalCount ?? 0;
      }, 0);
    })

    const open = () => {
      state.visible = true;
      state.disabledConfirmBtn = false;
      state.boxList = [];
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      columns,
      innerColumns,
      gToKg,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      getTotalBoxCount,
      handleUpload,
      handleDownloadExcel,
      handleConfirm,
      open,
      close,
    }
  }
})
</script>

<style scoped>
.card-title {
  font-size: 20px;
  font-weight: bold;
}
</style>