<!-- 按产品添加/添加产品 -->
<template>
  <a-modal
    width="auto"
    v-model:visible="visible"
    :title="$t('warehouse.add_product')"
    :centered="true"
    :maskClosable="false"
    :ok-button-props="{ disabled: selectedProductList.length === 0 }"
    @ok="handleConfirm"
  >
    <a-input-search
      v-model:value="searchState.searchKey"
      :placeholder="$t('warehouse.please_enter_product_name_product_number_seSku')"
      :enter-button="$t('common.search')"
      class="mb-3"
      :loading="tableData.loading"
      @search="handleSearch"
    />

    <a-table
      :columns="columns"
      :data-source="tableData.tableList"
      :scroll="{ x: 500, y: 600 }"
      :pagination="false"
      size="small"
      :loading="tableData.loading"
      :row-key="(record) => record.id"
    >
      <template #productNameAndSeSku>
        <!-- {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }} -->
        {{ $t("warehouse.product_info") }}
      </template>
      <template #boxCountTitle>
        {{ $t("warehouse.box_amount") }}
      </template>

      <template #productNameAndSeSkuCustom="{ record }">
        <a-row :gutter="[8, 8]">
          <a-col>
            <div class="table-list-img-common">
              <c-image
                :src="record.imgUrl"
                :thumbWidth="600"
                :thumbHeight="600"
              />
            </div>
          </a-col>
          <a-col flex="1" >
            <div>{{ record.productName }}</div>
            <div>{{ record.productNo }}</div>
          </a-col>
        </a-row>
      </template>
      <template #boxCount="{ record }">
        <a-input-number v-model:value="record.boxCount" :min="1" :precision="0"></a-input-number>
      </template>
      <template #addButtonCustom="{ record }">
        <div class="ant-form-inline">
          <a-button type="primary" v-if="record.selected" @click="handleSelectProduct(record)">{{ $t("common.cancel") }}</a-button>
          <a-button type="ghost" v-else @click="handleSelectProduct(record)">{{ $t("common.add") }}</a-button>
        </div>
      </template>
    </a-table>
    <CPager
      class="text-center mt-3"
      :showLessItems="true"
      :pageSizeOptions="['5', '10']"
      :page-data="tableData.pageData"
      @handlePage="handlePage"
    ></CPager>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import { Button, Col, Input, InputNumber, Modal, Row, Table } from "ant-design-vue";
import CImage from '@/views/components/CImage.vue';
import CPager from '@/views/components/CPager.vue';
import { getProductList } from "@/api/modules/product/index";

export default defineComponent({
  expose: ["open"],
  emits: ["confirm"],
  components: {
    AModal: Modal,
    AInputSearch: Input.Search,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInputNumber: InputNumber,
    AButton: Button,
    CImage,
    CPager,
  },
  setup(props, { emit }) {
    const state = reactive({
      visible: false,
      // 给某一箱添加产品
      boxRow: null,
      // 是否需要显示箱数列
      needBox: null,
      searchState: {
        searchKey: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 5,
        },
      },
      selectedProductList: [],
    });

    const startColumns = [
      {
        width: 350,
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
      },
      {
        dataIndex: "seSku",
        width: 200,
        title: "SESKU",
      },
    ]

    const endColumns = [
      {
        title: "",
        slots: {
          customRender: "addButtonCustom",
        },
        width: 120,
      },
    ]

    const boxColumns = [
      {
        slots: {
          title: "boxCountTitle",
          customRender: "boxCount",
        },
        width: 150,
      },
    ]

    const columns = computed(() => {
      if (state.needBox) {
        return startColumns.concat(boxColumns, endColumns);
      } else {
        return startColumns.concat(endColumns);
      }
    })

    const handleSelectProduct = (record) => {
      record.selected = !record.selected;
      if (record.selected) {
        state.selectedProductList.push(record);
      } else {
        let index = state.selectedProductList.findIndex(item => item.id === record.id);
        if (index > -1) {
          state.selectedProductList.splice(index, 1);
        }
      }
    }

    const handleConfirm = () => {
      const data = {
        list: JSON.parse(JSON.stringify(state.selectedProductList)),
        record: state.boxRow,
        needBox: state.needBox,
      }
      emit('confirm', data);
      close();
    };

    const getPageList = () => {
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      state.tableData.loading = true;
      getProductList(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          let selectedProductIds = state.selectedProductList.map(item => item.id);
          state.tableData.tableList = items.map(item => {
            if (selectedProductIds.includes(item.id)) {
              item.selected = true;
            } else {
              item.selected = false;
            }
            if (state.needBox) {
              item.boxCount = 1;
            }
            return item;
          });
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const open = (record, needBox = false) => {
      state.visible = true;
      state.searchState.searchKey = null;
      state.needBox = needBox;
      if (record) {
        state.boxRow = record;
        state.selectedProductList = Array.isArray(record.detailProducts) ? JSON.parse(JSON.stringify(record.detailProducts)) : [];
      } else {
        state.boxRow = null;
        state.selectedProductList = [];
      }
      state.tableData.tableList = [];
      handleInitPage();
    };

    const close = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      columns,
      handleSelectProduct,
      handleConfirm,
      open,
      close,
      handleSearch,
      handlePage,
    };
  },
});
</script>

<style scoped></style>
