<template>
  <a-steps :current="current" size="small" class="mb-3 pr-3">
    <a-step v-for="(item, index) in steps" :key="index" :title="item.title" />
  </a-steps>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Steps } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
  },
  props: {
    current: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      steps: [
        {
          title: () => vueI18n.t('logistics.create_transport_plan'),
        },
        {
          title: () => vueI18n.t('logistics.set_transport_info'),
        },
        {
          title: () => vueI18n.t('logistics.confirm_transport_plan'),
        },
        {
          title: () => vueI18n.t('warehouse.placed_an_order'),
        },
      ],
    });

    return {
      ...toRefs(state),
    }
  }
})
</script>

<style scoped>

</style>