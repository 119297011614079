<template>
  <Content>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <StepsRow :current="0" />
        <a-form ref="refForm"
                :label-col="{ sm: 5, md: 4, xl: 3, xxl: 2 }"
                :rules="rules"
                :model="data">
          <a-row v-if="data.selectedDestinationType==destinationTypeEnum.OtherAddress">
            <a-col :span="24" :xl="8" :xxl="6">
              <a-form-item :label-col="{ sm: 4, md: 3, xl: 6, xxl: 6 }"
                           name="selectedDestinationType"
                           :label="$t('logistics.destination_type')"
                           class="mt-3">
                <a-select v-model:value="data.selectedDestinationType"
                          :allowClear="true"
                          @change="handleChangeDestination"
                          :placeholder="$t('common.please_select')">
                  <a-select-option v-for="(value,key) in destinationTypeEnum"
                                   :key="key"
                                   :value="value">
                    {{$t($enumLangkey('destinationType',value))}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- 目的类型 -->
          <a-row v-else>
            <a-col :span="24">
              <a-form-item name="selectedDestinationType"
                           :label="$t('logistics.destination_type')"
                           class="mt-3">
                <a-select v-model:value="data.selectedDestinationType"
                          :allowClear="true"
                          @change="handleChangeDestination"
                          :placeholder="$t('common.please_select')"
                          class="max-w-330">
                  <a-select-option v-for="(value,key) in destinationTypeEnum"
                                   :key="key"
                                   :value="value">
                    {{$t($enumLangkey('destinationType',value))}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- 仓库类型 -->
          <a-row v-if="data.selectedDestinationType==destinationTypeEnum.SeWarehouse">
            <a-col :span="24">
              <a-form-item name="selectedWarehouseType"
                           :label="$t('warehouse.warehouse_type')">
                <a-select v-model:value="data.selectedWarehouseType"
                          :placeholder="$t('common.please_select')"
                          @change="handleGetWarehouseInfos"
                          class="max-w-330">
                  <a-select-option :value=" warehouseTypeEnum.storageWarehouse"
                                   :data-index="0">
                    {{ $t($enumLangkey('warehouseType', warehouseTypeEnum.storageWarehouse)) }}
                  </a-select-option>
                  <a-select-option :value="warehouseTypeEnum.consignmentWarehouse"
                                   :data-index="1">
                    {{ $t($enumLangkey('warehouseType', warehouseTypeEnum.consignmentWarehouse)) }}
                  </a-select-option>
                    <a-select-option :value="warehouseTypeEnum.transitWarehouse"
                                   :data-index="1">
                    {{ $t($enumLangkey('warehouseType', warehouseTypeEnum.transitWarehouse)) }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- 入库目标仓库 -->
          <a-row v-if="data.selectedDestinationType==destinationTypeEnum.SeWarehouse">
            <a-col :span="24">
              <a-form-item :label="$t('warehouse.target_warehouse')"
                           name="selectedTargetWarehouse">
                <a-select v-model:value="data.selectedTargetWarehouse"
                          @change="handleCheckHasWarehouseType"
                          class="max-w-330"
                          :showSearch="true"
                          optionFilterProp="search-key"
                          :loading="targetWarehousesLoading"
                          :placeholder="$t('common.please_select')">
                  <a-select-option v-for="item in targetWarehouses"
                                   :title="item.warehouseNo +'('+ item.warehouseName+')'"
                                   :search-key="item.warehouseNo + item.warehouseName"
                                   :key="item.id"
                                   :value="item.id">
                    {{item.warehouseNo}} ({{item.warehouseName}})
                  </a-select-option>
                </a-select>
                <span class="ml-2"
                      v-if="data.selectedTargetWarehouse">
                  <a-tag v-if="showTab == 'supportStorage'"
                         color="processing">
                    {{ $t('logistics.support_repository') }}
                  </a-tag>
                  <a-tag v-else-if="showTab == 'supportTransit'"
                         color="warning">
                    {{ $t('logistics.support_distribution_warehouse') }}
                  </a-tag>
                </span>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- FBA目标仓库 -->
          <a-row v-if="
          data.selectedDestinationType==destinationTypeEnum.FBA
          ||
          data.selectedDestinationType==destinationTypeEnum.mercadolibre
          ">
            <a-col :span="24">
              <a-form-item name="selectedTargetWarehouse">
                <template #label>
                  <span v-if="
                  data.selectedDestinationType==destinationTypeEnum.FBA">
                    {{$t('warehouse.fba_warehouse_code')}}
                  </span>
                  <span v-else>{{$t('warehouse.fbm_warehouse_code')}}</span>
                </template>

                <a-select v-model:value="data.selectedTargetWarehouse"
                          @change="funcGetFabAddress"
                          class="max-w-330"
                          :loading="targetWarehousesLoading"
                          :placeholder="$t('common.please_select')"
                          show-search
                          optionFilterProp="search-key">
                  <a-select-option v-for="item in targetWarehouses"
                                   :key="item.id"
                                   :value="item.id"
                                   :search-key="item.name">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- 仓库地址 -->
          <a-row v-if="
          data.selectedDestinationType&&data.selectedDestinationType!=destinationTypeEnum.OtherAddress
          ">
            <a-col :span="24">
              <a-form-item :label="$t('warehouse.warehouse_address')">
                <span>{{getAddress()}}</span>
                <span class="ml-5"
                      v-if="targetAddress && data.selectedDestinationType == destinationTypeEnum.SeWarehouse">{{ $t('logistics.contacts') }}: {{getLinkman(targetAddress)}}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- 亚马逊运输编号 -->
          <a-row v-if="
          data.selectedDestinationType==destinationTypeEnum.FBA
          ">
            <a-col :span="24">
              <a-form-item name="fbaTransportNo"
                           :label="$t('warehouse.amazon_shipping_number')">

                <a-input v-model:value="data.fbaTransportNo"
                         class="max-w-330"
                         :placeholder="$t('logistics.fba_transport_no_placeholder')" />
              </a-form-item>
            </a-col>
          </a-row>

          <!--fbm Seller ID -->
          <a-row v-if="
          data.selectedDestinationType==destinationTypeEnum.mercadolibre
          ">
            <a-col :span="24">
              <a-form-item name="sellerId"
                           :label="$t('warehouse.seller_id')">
                <a-input v-model:value="data.fbaTransportNo"
                         class="max-w-330" />
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 货件追踪编号 -->
          <a-row v-if="data.selectedDestinationType==destinationTypeEnum.FBA">
            <a-col :span="24">
              <a-form-item name="productTrackNo"
                           :label="$t('warehouse.shipment_tracking_number')">
                <a-input v-model:value="data.productTrackNo"
                         class="max-w-330"
                         :placeholder="$t('logistics.product_track_no_placeholder')" />
              </a-form-item>
            </a-col>
          </a-row>

          <!--  货件ID -->
          <a-row v-if="data.selectedDestinationType==destinationTypeEnum.mercadolibre">
            <a-col :span="24">
              <a-form-item name="warehousingNumber"
                           :label="$t('warehouse.warehousing_number')">
                <a-input v-model:value="data.productTrackNo"
                         class="max-w-330" />
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 其他地址 -->
          <Address v-if="data.selectedDestinationType==destinationTypeEnum.OtherAddress"
                   ref="refToAddress"
                   :addressData="data.addressData"
                   :isShowSelectCommonAddress="true"
                   :isShowButtonSaveCommon="false"
                   :isShipAddress="false"
                   :cacheObj="data.addressCacheObj"></Address>

          <div class="table">
            <a-row :gutter="16" class="mb-3">
              <a-col>
                <a-button type="primary" ghost @click="handleShowModalProduct(null, true)">{{$t('warehouse.add_by_the_product')}}</a-button>
              </a-col>
              <a-col>
                <a-button type="primary" ghost @click="handleAddBox(true)">{{ $t('warehouse.add_box') }}</a-button>
              </a-col>
              <a-col>
                <a-button type="primary" ghost @click="handleOpenImportModal()">{{ $t('warehouse.quick_import') }}</a-button>
              </a-col>
            </a-row>
            <a-empty v-if="data.boxList.length === 0" :image="simpleImage"></a-empty>
            <a-card class="mb-3" size="small" v-for="(card, i) in data.boxList" :key="i" :body-style="{ paddingLeft: '100px' }" hoverable>
              <template #title>
                <a-space>
                  <span class="card-title">{{ i + 1 }}.</span>
                  <a-input-number v-model:value="card.totalCount" :min="1" :max="2000" :precision="0" class="card-title" style="width: 70px;"></a-input-number>
                  <span class="card-title">{{ $t('warehouse.box') }}</span>
                </a-space>
                <a-button class="ml-4" type="ghost" danger @click="handleDelete(card)">{{$t('common.remove')}}</a-button>
              </template>
              <a-table :columns="columns"
                      :data-source="[card]"
                      size="small"
                      :pagination="false"
                      :expanded-row-keys="data.boxExpandedRowKeysList"
                      :expandIconAsCell="false"
                      :expandIconColumnIndex="1"
                      :row-key="(record) => record.rowKey">
                <template #selfBoxNo="{ record }">
                  <template v-if="record.selfBoxNoList.length > 0">
                    <a-tag closable @close.prevent="handleDeleteBoxNo(record, 0)"><span>{{ record.selfBoxNoList[0] }}</span></a-tag>
                    <a-popover :title="$t('common.user_defined_box_no')" placement="right" trigger="click" v-if="record.selfBoxNoList.length > 1">
                      <template #content>
                        <div style="max-width: 560px;">
                          <a-tag class="mb-2" v-for="(tag, ind) in record.selfBoxNoList" :key="ind" closable @close.prevent="handleDeleteBoxNo(record, ind)">
                            <span>{{ tag }}</span>
                          </a-tag>
                        </div>
                      </template>
                      <a-tag color="processing"><span>+{{ record.selfBoxNoList.length - 1 }} ...</span></a-tag>
                    </a-popover>
                  </template>
                  <small v-else class="text-grey">{{ $t('common.nothing') }}</small>
                  <a-button type="link" @click="handleOpenCustomBoxNumberModal(record)"><EditOutlined /></a-button>
                </template>
                <template #expandIcon="{ expanded, record }">
                  <CHaveProductInformation :open="expanded" @click="handleOpenOrCloseRow(record)" />
                </template>
                <template #encasementSizeCustom="{record }">
                  <a-input-number v-model:value="record.encasementLength"
                                  :precision="0"
                                  class="mr-1"
                                  :class="[tableVerified&&record.encasementLength<=0 ?'input-error-border':'']"
                                  :placeholder="$t('common.max_length_side')"
                                  :min="1"
                                  :max="99999" />x
                  <a-input-number v-model:value="record.encasementWidth"
                                  class="mr-1"
                                  :precision="0"
                                  :class="[tableVerified&&record.encasementWidth<=0 ?'input-error-border':'']"
                                  :placeholder="$t('common.second_length_side')"
                                  :min="1"
                                  :max="99999" />x
                  <a-input-number v-model:value="record.encasementHeight"
                                  :precision="0"
                                  :class="[tableVerified&&record.encasementHeight<=0 ?'input-error-border':'']"
                                  :placeholder="$t('common.least_length_side')"
                                  :min="1"
                                  :max="99999" /> cm
                </template>

                <template #encasementGrossWeightCustom="{record }">
                  <a-input-number v-model:value="record.encasementGrossWeight"
                                  :precision="3"
                                  :class="[tableVerified&&record.encasementGrossWeight<=0 ?'input-error-border':'']"
                                  :min="0.001"
                                  :max="999999.999" /> kg
                </template>

                <template #inWarehouseBoxCountCustom="{ record }">
                  {{ getBoxProductCount(record) }} Unit
                </template>

                <template #operateCustom="{ record }">
                  <a-row :gutter="8">
                    <a-col>
                      <a-button type="primary" ghost @click="handleShowModalProduct(record, false)">{{$t('warehouse.add_product')}}</a-button>
                    </a-col>
                  </a-row>
                </template>
                <template #expandedRowRender="{ record }">
                  <a-table
                    :columns="innerColumns"
                    :data-source="record.detailProducts"
                    :pagination="false"
                    :row-key="(record) => record.id"
                    size="small"
                  >
                    <template #productInfo="{ record }">
                      <a-row :gutter="8" type="flex" align="middle">
                        <a-col>
                          <div class="table-list-img-common">
                            <c-image
                              :src="record.imgUrl"
                              :thumbWidth="600"
                              :thumbHeight="600"
                            />
                          </div>
                        </a-col>
                        <a-col flex="1">
                          <div>{{ record.productName }}</div>
                          <div>{{ record.productNo }}</div>
                        </a-col>
                      </a-row>
                    </template>
                    <template #productCount="{ record }">
                      <a-input-number
                        v-model:value="record.productCount"
                        :min="1"
                        :max="9999"
                        :precision="0"
                      ></a-input-number> Unit
                    </template>
                    <template #operation="{ record, index }">
                      <a-button type="ghost" danger @click="handleRemoveBoxProduct(record.parentRowKey, index)">{{ $t('common.remove') }}</a-button>
                    </template>
                  </a-table>
                </template>
              </a-table>
            </a-card>
            <div style="text-align: right;">{{ $t('common.total') }}: {{ getTotalBoxCount }} {{ $t('warehouse.box') }}</div>
          </div>
        </a-form>

        <!-- 常用地址 -->
        <div class="mt-5">
          <Address ref="refFromAddress"
                   :addressData="data.addressData"
                   :isShowSelectCommonAddress="true"
                   :cacheObj="data.addressCacheObj"></Address>
        </div>

        <!-- button创建计划 -->
        <div class="mr-5 mb-5 ">
          <a-row type="flex"
                 justify="end">
            <a-col>
              <a-button type="primary"
                        :disabled="isConfirm"
                        @click="funcValidateData">{{$t('warehouse.created_plan')}}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
      <!-- 添加产品modal -->
      <ProductListModal ref="productListModalRef" @confirm="handleAddProductToRow" />

      <!-- 添加出库计划的名称 -->
      <a-modal v-model:visible="isShowModalAddPlanName"
               :title="$t('warehouse.add_plan_name')"
               @ok="handleCreatePlan"
               :cancelText="$t('common.cancel')"
               :okText="$t('warehouse.created_plan')"
               :centered="true"
               :mask-closable="false"
               :confirm-loading="addPlanNameModalOkLoading">
        <div>
          <input type="text"
                 class="display-none" />
          <a-row type="flex"
                 justify="center"
                 align="middle">
            <a-col>
              <p class="height-100">{{$t('warehouse.please_input_plan_name')}}</p>
            </a-col>
          </a-row>
          <a-row type="flex"
                 justify="center"
                 align="middle">
            <a-col :span="15">
              <a-input v-model:value="data.planName"
                       required="true"
                       ref="refInputAddPlanName"
                       @focus="($event) => {$event.currentTarget.select();}"
                       @pressEnter="handleCreatePlan" />
            </a-col>
          </a-row>
        </div>
      </a-modal>
      <ImportProductModal ref="importProductModalRef" @confirm="handleSetBoxList" />
      <CustomBoxNumberModal v-model:visible="customBoxNumberModalState.visible" :list="customBoxNumberModalState.list" :max="customBoxNumberModalState.max" @confirm="handleSetBoxNoList" />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, ref, h, nextTick, computed } from "vue";
import { useStore } from "vuex";
import {
  Row, Col, Steps, Tag, Empty,
  Table, Input, InputNumber, Card,
  Select, Button, Form, Modal, Spin, Space, message,
  Popover,
} from "ant-design-vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import CPager from "@/views/components/CPager.vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue"
import Address from "@/views/components/Address.vue"
import CHaveProductInformation from "@/views/components/CHaveProductInformation.vue"
import ImportProductModal from './components/ImportProductModal.vue';
import StepsRow from './components/StepsRow.vue'
import ProductListModal from './components/ProductListModal.vue'
import CustomBoxNumberModal from './components/CustomBoxNumberModal.vue'
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import { useTab } from "@/hooks/tabs/index";
import { getWarehouses, checkZipCodeValid } from "@/api/modules/common/index";
import { checkSupportTransit, createTransportPlan, getFbaOrFbmWarehouse } from "@/api/modules/transportation/index";
import { getAddressByLanguageV2, isInteger, createAutoPlanName, gToKg, kgToG, getLinkman, randomString } from "@/utils/general"
import { warehouseType as warehouseTypeEnum, destinationType as destinationTypeEnum } from "@/enum/enum.json";

export default ({
  name: "transport_create_plan",
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputNumber: InputNumber,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpin: Spin,
    ASpace: Space,
    ACard: Card,
    AEmpty: Empty,
    APopover: Popover,
    Content,
    CImage,
    CPager,
    Address,
    StepsRow,
    ProductListModal,
    ImportProductModal,
    CHaveProductInformation,
    CustomBoxNumberModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    const refForm = ref();
    const refFromAddress = ref();
    const refToAddress = ref();
    const { getters } = useStore();
    const refInputAddPlanName = ref();
    const productListModalRef = ref(null);

    const state = reactive({
      destinationTypeEnum,
      warehouseTypeEnum,
      buttonAddProductLoading: false,
      buttonCreatePlanLoading: false,
      addPlanNameModalOkLoading: false,
      targetWarehousesLoading: false,
      isShowModalAddPlanName: false,

      pageLoading: false,
      targetWarehouses: [],
      targetAddress: "",
      showTab: "",//是否显示标签文字"支持配送仓or存储仓",
      tableVerified: false,
      isConfirm: false,
    })

    const data = reactive({
      planId: "",//planNo
      planName: "",

      selectedDestinationType: null,
      selectedWarehouseType: null,
      selectedTargetWarehouse: null,
      boxList: [],
      boxExpandedRowKeysList: [],
      modalSearchValue: "",
      fbaTransportNo: "",
      productTrackNo: "",

      toAddressData: {
        inputAddressAlias: "",
        selectedCommonAddress: null,
        commonAddressName: "",
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: "",
        postCode: ""//邮编
      },
      addressData: {
        inputAddressAlias: "",
        selectedCommonAddress: null,
        commonAddressName: "",
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: "",
        postCode: ""//邮编
      },
      addressCacheObj: {}
    });

    const columns = [
      {
        width: 250,
        title: () => vueI18n.t("common.user_defined_box_no"),
        slots: {
          customRender: "selfBoxNo"
        }
      },
      { width: 50, },
      {
        width: 400,
        title: () => vueI18n.t('warehouse.encasement_size'),
        slots: {
          customRender: "encasementSizeCustom",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.encasement_weight'),
        slots: {
          customRender: "encasementGrossWeightCustom",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.encasement_count'),
        slots: {
          customRender: "inWarehouseBoxCountCustom",
        },
      },
      {
        width: 250,
        slots: {
          customRender: "operateCustom",
        },
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 350,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
       {
        width: 200,
        title: ('SESKU'),
        dataIndex: "seSku",
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "productCount"
        }
      },
      {
        slots: {
          customRender: "operation"
        }
      },
    ];

    const validateWarehouseType = async (rule, value) => {
      if (data.selectedWarehouseType) {
        return Promise.resolve();
      }
      return Promise.reject();
    };

    const rules = {
      selectedDestinationType: [{
        required: true,
        type: 'number',
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.destination_type")])
        }
      }],
      selectedWarehouseType: [{
        required: true,
        validator: validateWarehouseType,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.warehouse_type")])
        }
      }],
      selectedTargetWarehouse: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.target_warehouse")])
        }
      }],
      fbaTransportNo: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.amazon_shipping_number")])
        }
      }],
      productTrackNo: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.shipment_tracking_number")])
        }
      }],
      sellerId: [{
        required: true,
        whitespace: true,
        validator: (rule, value) => {
          if (data.fbaTransportNo) {
            return Promise.resolve();
          }
          return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.seller_id")]));
        }
      }],
      warehousingNumber: [{
        required: true,
        whitespace: true,
        validator: (rule, value) => {
          if (data.productTrackNo) {
            return Promise.resolve();
          }
          return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.warehousing_number")]));
        }
      }],
    };
    
    const getAddress = () => {
      return getAddressByLanguageV2(state.targetAddress, getters.language);
    }

    const getBoxProductCount = (record) => {
      if (!Array.isArray(record.detailProducts)) {
        return 0;
      }
      let count = record.detailProducts.reduce((total, item) => {
        if (item.productCount && item.productCount > 0) {
          return total += item.productCount;
        }
      }, 0) || 0;
      record.inWarehouseBoxCount = count;
      return count;
    };

    const handleAddBox = (showMsg = false) => {
      let obj = {
        rowKey: randomString(12),
        selfBoxNo: null,
        totalCount: 1,
        selfBoxNoList: [],
        encasementLength: null,
        encasementWidth: null,
        encasementHeight: null,
        encasementGrossWeight: null,
        inWarehouseBoxCount: null,
        detailProducts: [],
      }
      data.boxList.push(obj);
      if (showMsg) {
        message.success(vueI18n.t("common.succeed"));
      }
    }

    const handleDelete = (record) => {
      data.boxList = data.boxList.filter(item => item.rowKey != record.rowKey);
    };

    const handleRemoveBoxProduct = (parentRowKey, index) => {
      let boxItem = data.boxList.find(item => item.rowKey === parentRowKey);
      if (boxItem) {
        boxItem.detailProducts.splice(index, 1);
      }
    }

    // 设置全部行展开
    const handleSetAllExpandedRows = () => {
      data.boxExpandedRowKeysList = data.boxList?.map(item => item.rowKey);
    }

    // 展开关闭行
    const handleOpenOrCloseRow = (record, keepOpen = false) => {
      let rowKey = record.rowKey;
      if (data.boxExpandedRowKeysList.includes(rowKey)) {
        if (!keepOpen) {
          data.boxExpandedRowKeysList = data.boxExpandedRowKeysList.filter(item => item !== rowKey);
        }
      } else {
        data.boxExpandedRowKeysList.push(rowKey);
      }
    }

    // 添加产品
    const handleAddProductToRow = ({ list, record, needBox }) => {
      if (needBox) {
        list.forEach(item => {
          if (!('productCount' in item)) {
            item.productCount = item.containerCount;
          }
          handleAddBox();
          let boxInfo = data.boxList[data.boxList.length - 1];
          item = JSON.parse(JSON.stringify(item));
          item.parentRowKey = boxInfo.rowKey;
          boxInfo.totalCount = item.boxCount;
          boxInfo.selfBoxNoList = [];
          // 设置箱尺寸
          boxInfo.encasementLength = item.containerLength;
          boxInfo.encasementWidth = item.containerWidth;
          boxInfo.encasementHeight = item.containerHeight;
          boxInfo.encasementGrossWeight = gToKg(item.containerWeight);

          boxInfo.detailProducts.push(item);
        });
        handleSetAllExpandedRows();
      } else {
        let item = data.boxList.find(item => item.rowKey === record.rowKey);
        item.detailProducts = list.map(item => {
          if (!('productCount' in item)) {
            item.productCount = item.containerCount;
          }
          item.parentRowKey = record.rowKey;
          return item;
        });
        handleOpenOrCloseRow(record, true);
      }
      message.success(vueI18n.t("common.succeed"));
    };

    // 打开产品弹窗
    const handleShowModalProduct = (record = null, needBox = false) => {
      productListModalRef.value.open(record, needBox);
    };

    const _funcCheckTableRequired = () => {
      return (data.boxList.findIndex(x => {
        return x.totalCount <= 0 ||
          x.encasementLength <= 0 ||
          x.encasementWidth <= 0 ||
          x.encasementHeight <= 0 ||
          x.encasementGrossWeight <= 0 ||
          x.inWarehouseBoxCount <= 0 ||
          x.detailProducts.length <= 0
      }) === -1)
    }

    const _funcCheckTableSizeIsInteger = () => {
      return (data.boxList.findIndex(
        x =>
          !isInteger(x.encasementLength)
          || !isInteger(x.encasementWidth)
          || !isInteger(x.encasementHeight)
      )
        > -1)
    }

    const _funcCheckProductRequired = () => {
      let result = true;
      data.boxList.forEach(item => {
        item.detailProducts.forEach(product => {
          if (!product.productCount) {
            result = false;
          }
        });
      });
      return result;
    }

    const _funcResetSize = async () => {
      let saveTabel = JSON.parse(JSON.stringify(data.boxList));

      saveTabel.forEach(x => {
        let [length, width, height] = [x.encasementLength, x.encasementWidth, x.encasementHeight].sort((a, b) => b - a);
        x.encasementLength = length;
        x.encasementWidth = width;
        x.encasementHeight = height;
      });
      data.boxList = saveTabel;
      return saveTabel;
    }

    const _whetherNeedRestSize = () => {
      return data.boxList.findIndex(x => {
        return !(x.encasementLength >= x.encasementWidth
          && x.encasementWidth >= x.encasementHeight)
      }) != -1
    }

    const handleShowConfirm = () => {
      Modal.confirm({
        title: vueI18n.t("common.system_automatically_helps_repair"),
        icon: h(ExclamationCircleOutlined),
        content: vueI18n.t("common.current_size_fill_in_error"),
        centered: true,
        mask: true,
        maskClosable: false,
        keyboard: false,
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk () {
          _funcResetSize()
        },
      });
    }

    const handleGetWarehouseInfos = () => {
      state.showTab = "";
      data.selectedTargetWarehouse = undefined;
      state.targetAddress = "";
      let searchData = {
        "warehouseType": data.selectedWarehouseType,
        "isActive": true,
      }
      if (data.selectedDestinationType == destinationTypeEnum.SeWarehouse) {
        state.targetWarehousesLoading = true;
        return getWarehouses(searchData).then((res) => {
          state.targetWarehousesLoading = false;
          state.targetWarehouses = res.result;
        }).catch(() => {
          state.targetWarehousesLoading = false;
        })
      }
    }

    //判断是否为配送仓或存储仓
    const handleCheckHasWarehouseType = (value, option) => {
      if (data.selectedDestinationType == destinationTypeEnum.SeWarehouse) {
        let selectedWarehouseInfo = state.targetWarehouses.find(x => x.id == data.selectedTargetWarehouse);
        state.targetAddress = selectedWarehouseInfo;
        if (data.selectedTargetWarehouse) {
          // 这里只判断了 一个 ,需要判断多个
          let warehouseTypeVal = data.selectedWarehouseType === warehouseTypeEnum.storageWarehouse ? warehouseTypeEnum.consignmentWarehouse : warehouseTypeEnum.storageWarehouse
          let searchData = {
            warehouseType: warehouseTypeVal,
            warehouseId: data.selectedTargetWarehouse
          };
          checkSupportTransit(searchData).then((res) => {
            if (res.result) {
              if (data.selectedWarehouseType === warehouseTypeEnum.storageWarehouse) {
                state.showTab = "supportTransit";
              } else if (data.selectedWarehouseType === warehouseTypeEnum.consignmentWarehouse) {
                state.showTab = "supportStorage";
              }
            } else {
              state.showTab = "";
            }
          })
        } else {
          state.showTab = "";
        }
      }
    }

    const funcGetFabAddress = () => {
      let selectedWarehouseInfo = state.targetWarehouses.find(x => x.id == data.selectedTargetWarehouse);
      if (selectedWarehouseInfo) {
        state.targetAddress = selectedWarehouseInfo;
      }
    }

    const handleChangeDestination = () => {
      if (data.selectedDestinationType == destinationTypeEnum.FBA
        ||
        data.selectedDestinationType == destinationTypeEnum.mercadolibre
      ) {
        data.selectedTargetWarehouse = null;
        state.targetAddress = '';
        data.fbaTransportNo = null;
        data.productTrackNo = null;

        let platform = data.selectedDestinationType == destinationTypeEnum.FBA ? 1 : 2;
        getFbaOrFbmWarehouse({ 'platform': platform }).then((res) => {
          state.targetWarehouses = (res.result || []).map(x => {
            let address = [x.addressLine1, x.addressLine2].filter(Boolean).join(', ')
            return Object.assign({ address }, x);
          });
        })
      } else if (data.selectedDestinationType == destinationTypeEnum.SeWarehouse) {
        data.selectedWarehouseType = null
        data.selectedTargetWarehouse = null
        state.targetWarehouses = []
      }
    }

    const funcValidateData = () => {
      refForm.value
        .validate()
        .then(() => {
          if (data.boxList.length == 0) {
            state.tableVerified = true;
            message.error(vueI18n.t("warehouse.product_has_not_added"));
            return Promise.reject();
          } else if (!_funcCheckTableRequired()) {
            state.tableVerified = true;
            message.error(vueI18n.t("warehouse.incomplete_packing_data"));
            return Promise.reject();
          } else if (_funcCheckTableSizeIsInteger()) {
            state.tableVerified = true;
            message.error(vueI18n.t("common.p0_must_be_an_integer", [vueI18n.t("warehouse.encasement_size")]));
            return Promise.reject();
          } else if (!_funcCheckProductRequired()) {
            state.tableVerified = true;
            message.error(vueI18n.t("warehouse.product_not_filled_full"));
            return Promise.reject();
          } 
          else {
            return Promise.resolve();
          }
        })
        .then(() => {
          if (_whetherNeedRestSize()) {
            handleShowConfirm();
            return Promise.reject();
          } else {
            return Promise.resolve();
          }
        })
        .then(() => {
          if (data.selectedDestinationType == destinationTypeEnum.OtherAddress) {
            return refToAddress.value.refForm.validate();
          }
        })
        .then(() => {
          return refFromAddress.value.refForm.validate();
        })
        .then(() => {
          handleCheckPostCode();
        })
        .catch(() => {
        });
    };

    const handleCheckPostCode = async () => {
      try {
        // 检查收货地址
        if (data.selectedDestinationType == destinationTypeEnum.OtherAddress) {
          let toAddressState = refToAddress.value.getAddressInfo();
          if (!toAddressState.abbrCode) {
            // 没有省份的情况下跳过验证邮编
            handleShowAddNameModal();
          } else {
            const checkToPostCodeData = {
              zipCode: toAddressState.postCode,
              countryId: toAddressState.countryId,
              abbrCode: toAddressState.abbrCode,
            }
            let { result: toResult } = await checkZipCodeValid(checkToPostCodeData);
            if (toResult === false) {
              await Modal.error({
                title: vueI18n.t("common.hint"),
                content: "【" + vueI18n.t("logistics.receiving_address") + "】" + vueI18n.t("warehouse.the_postal_code_you_entered_does_not_match"),
                okText: vueI18n.t("common.return_to_modification"),
              });
            } else if (toResult === null) {
              await Modal.confirm({
                title: vueI18n.t("common.hint"),
                icon: h(ExclamationCircleOutlined),
                content: "【" + vueI18n.t("logistics.receiving_address") + "】" + vueI18n.t("warehouse.the_correctness_of_the_postal_code_you_entered_is_unknown"),
                cancelText: vueI18n.t("common.return_to_modification"),
                okText: vueI18n.t("common.continue"),
                onOk: () => {
                  handleShowAddNameModal();
                },
              });
            } else if (toResult === true) {
              handleShowAddNameModal();
            }
          }
        } else {
          handleShowAddNameModal();
        }
      } catch (error) {
      }
    }

    const handleShowAddNameModal = () => {
      data.planName = createAutoPlanName();
      state.isShowModalAddPlanName = true;
      nextTick(() => {
        refInputAddPlanName.value.focus();
      });
    };

    const handleCreatePlan = () => {
      if (!data.planName.trim()) {
        message.error(vueI18n.t("logistics.plan_name_not_null"))
        refInputAddPlanName.value.focus();
        return false
      }
      let transportPlanDetails = [];

      data.boxList.forEach((x) => {
        let list = new Array(x.totalCount).fill().map((box, index) => {
          let detailProducts = x.detailProducts.map(item => ({
            productId: item.id,
            productCount: item.productCount,
          }))
          let obj = {
            selfBoxNo: null,
            containerLength: x.encasementLength,
            containerWidth: x.encasementWidth,
            containerHeight: x.encasementHeight,
            containerWeight: kgToG(x.encasementGrossWeight),
            detailProducts,
          }

          obj.selfBoxNo = index < x.selfBoxNoList.length ? x.selfBoxNoList[index] : null;

          return obj;
        });

        transportPlanDetails = transportPlanDetails.concat(list);
      });

      let pra = {
        planName: data.planName,
        destinationType: data.selectedDestinationType,
        toWarehouseId: data.selectedTargetWarehouse,
        toWarehouseType: data.selectedWarehouseType,
        amazonTransportNo: data.fbaTransportNo,
        trackNo: data.productTrackNo,
        transportPlanDetails,
        fromAddress: {
          "id": refFromAddress.value.data.selectedCommonAddress,
          "name": refFromAddress.value.data.commonAddressName,
          "countryId": refFromAddress.value.data.selectedCountrys,
          "provinceId": refFromAddress.value.data.selectedProvinces,
          "cityId": refFromAddress.value.data.selectedCity,
          "countyId": refFromAddress.value.data.selectedCounty,
          "address": refFromAddress.value.data.detailedAddress,
          "address2": refFromAddress.value.data.detailedAddress2,
          "postCode": refFromAddress.value.data.postCode,
        },
        toAddress: {
          "id": refToAddress.value?.data.selectedCommonAddress,
          "name": refToAddress.value?.data.commonAddressName,
          "countryId": refToAddress.value?.data.selectedCountrys,
          "provinceId": refToAddress.value?.data.selectedProvinces,
          "cityId": refToAddress.value?.data.selectedCity,
          "countyId": refToAddress.value?.data.selectedCounty,
          "address": refToAddress.value?.data.detailedAddress,
          "address2": refToAddress.value?.data.detailedAddress2,
          "postCode": refToAddress.value?.data.postCode,
        },
      };
      state.isShowModalAddPlanName = false;
      state.pageLoading = true;
      createTransportPlan(pra).then((res) => {
        if (res.result) {
          message.success(vueI18n.t("common.succeed"));
          delVisitedRoute(router.currentRoute.value);
          router.push({ path: "/transport/setplaninfo/" + res.result });
        }
      }).finally(() => {
        state.pageLoading = false;
      })
    };

    //-------------快速导入 start----------------
    const importProductModalRef = ref(null);
    const handleOpenImportModal = () => {
      importProductModalRef.value.open();
    }

    const getCardKey = (item) => {
      let length = item.encasementLength || item.containerLength;
      let width = item.encasementWidth || item.containerWidth;
      let height = item.encasementHeight || item.containerHeight;
      let weight = (item.encasementGrossWeight ? kgToG(item.encasementGrossWeight) : 0) || item.containerWeight;

      let key = length + '_' + width + '_' + height + '_' + weight;
      let list = item?.items ?? item.detailProducts;
      list.forEach(product => {
        key += '_' + product.seSku + '_' + product.containerCount || product.productCount;
      })
      return key;
    };

    // 设置箱
    const handleSetBoxList = (list) => {
      if (Array.isArray(list)) {
        list.forEach(x => {
          let box = {
            rowKey: x.rowKey,
            selfBoxNo: x.selfBoxNo,
            totalCount: x.totalCount,
            selfBoxNoList: x.selfBoxNoList,
            encasementLength: x.containerLength,
            encasementWidth: x.containerWidth,
            encasementHeight: x.containerHeight,
            encasementGrossWeight: x.containerWeight,
            inWarehouseBoxCount: x.containerCount,
            detailProducts: x.items
          }

          if (box?.detailProducts && box?.detailProducts?.length > 0 ) {
            box.detailProducts = box?.detailProducts?.map(p=>{
              if (p.isNormal) {
                return {
                  id: p.productId,
                  productId: p.productId,
                  rowKey: p.rowKey,
                  parentRowKey: x.rowKey,
                  productName: p.productName,
                  productNo: p.productNo,
                  productCount: p.containerCount,
                  seSku: p.seSku,
                  imgUrl: p.imgUrl,
                }
              }
            })
          }
          
          data.boxList.push(box);
        });
        message.success(vueI18n.t("common.succeed"));
      }
      handleSetAllExpandedRows();
    }
    //-------------快速导入 end----------------

    const customBoxNumberModalState = reactive({
      visible: false,
      list: [],
      dataTemp: null,
      max: null,
    });

    const handleDeleteBoxNo = (record, index) => {
      record.selfBoxNoList.splice(index, 1);
    }

    const handleOpenCustomBoxNumberModal = (card) => {
      customBoxNumberModalState.dataTemp = card;
      customBoxNumberModalState.list = card.selfBoxNoList;
      customBoxNumberModalState.max = card.totalCount;
      customBoxNumberModalState.visible = true;
    }

    // 设置自定义箱号
    const handleSetBoxNoList = (list) => {
      let boxInfo = data.boxList.find(item => item.rowKey === customBoxNumberModalState.dataTemp?.rowKey);
      if (boxInfo) {
        boxInfo.selfBoxNoList = list;
      }
    }

    const getTotalBoxCount = computed(() => {
      return data.boxList.reduce((total, item) => {
        return total + item.totalCount ?? 0;
      }, 0);
    })

    return {
      ...toRefs(state),
      data,
      getLinkman,
      columns,
      innerColumns,
      rules,
      refForm,
      refInputAddPlanName,
      refFromAddress,
      refToAddress,
      productListModalRef,
      customBoxNumberModalState,
      getTotalBoxCount,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      getAddress,
      getBoxProductCount,
      handleOpenOrCloseRow,
      handleAddBox,
      handleDelete,
      handleRemoveBoxProduct,
      handleAddProductToRow,
      handleCreatePlan,
      handleGetWarehouseInfos,
      handleShowModalProduct,
      handleCheckHasWarehouseType,
      handleChangeDestination,
      funcGetFabAddress,
      funcValidateData,

      importProductModalRef,
      handleOpenImportModal,
      handleSetBoxList,
      handleDeleteBoxNo,
      handleOpenCustomBoxNumberModal,
      handleSetBoxNoList,
    };
  }
})
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
}
</style>